import { Component } from 'react';

export interface Article {
  id: string;
  date: string;
  title: string;
  briefTitle: string;
  subheading?: string;
  slug: string;
  seo: SeoSettings;
  meta: ArticleMeta;
  hero: ArticleHero;
  content: ArticleContent;
  relatedLinks: RelatedLinks | null;
  studentWorkBlock: ArticleStudentWorkBlock | null;
  authorsReferences: ArticleAuthorsReferences;
  galleryBlock: BlockGallery;
}

export type ArticleAuthorsReferences = ArticleAuthorsReferencesBody[];

export type ArticleAuthorsReferencesBody =
  | ArticleAuthorsReferencesTextContent
  | TwoColumnTextBlock;

export type ArticleAuthorsReferencesTextContent = PortableText;

export type ArticleBody =
  | RelatedLinks
  | ArticleTextContent
  | ArticleImageBlock
  | ArticleVideoBlock
  | ArticleEventBlock
  | ArticleImageCarouselBlock;

export interface ArticleContent {
  body: PortableText;
}

export interface ArticleHero {
  heroImage?: Image;
  model?: File3D;
}

export interface ArticleEventBlock {
  type: 'articleEventBlock';
  id: string;
  header: string;
  location: string;
  startDate: string;
  endDate: string;
  description?: string;
}

export interface ArticleImageBlock {
  id: string;
  type: 'articleImageBlock';
  src: string;
  caption: string;
  alt: string;
}

export interface ArticleImageCarouselBlock {
  type: 'articleImageCarouselBlock';
  id: string;
  slides: ArticleImageCarouselBlockSlide[];
}

export interface ArticleImageCarouselBlockSlide {
  image: Image;
  slideText?: string;
}

export interface ArticleLink {
  id: string;
  title: string;
  briefTitle: string;
  heroImage: Image;
  model: File3D;
  authorsInfo: AuthorsInfo;
  date: string;
  slug: string;
  topics: TopicLink[];
}

export interface ArticleMeta {
  date: string;
  authorsInfo?: {
    authors: Author[];
    details?: string;
  };
  topics: TopicLink[];
}

export interface ArticleResponse {
  article: Article | null;
  globalSettings: GlobalSettings | null;
}

export interface ArticleScheduleBlock {
  type: 'articleScheduleBlock';
  id: string;
  items: ArticleScheduleBlockItem[];
}

export interface ArticleScheduleBlockItem {
  id: string;
  time: string;
  event: string;
}

export interface ArticleStudentWorkBlock {
  type: 'articleStudentWorkBlock';
  images: Image[];
}

export interface ArticleTextContent {
  id: string;
  type: 'block';
  children: any;
  style: string;
}

export interface ArticleVideoBlock {
  id: string;
  type: 'articleVideoBlock';
  videoId: string;
}

export interface Author {
  firstName: string;
  lastName: string;
}

export interface AuthorsInfo {
  authors: Author[];
  details?: string;
}

export enum BackgroundColorType {
  BLACK = 'black',
  BRONZE = 'bronze',
  WHITE = 'white',
}

export type Block =
  | BlockImageText
  | BlockFacultyMembers
  | BlockGallery
  | BlockHero
  | BlockQuote
  | BlockVideo
  | BlockText
  | BlockSocialMedia
  | BlockTopicsList
  | BlockEvent;

export interface BlockEvent {
  type: 'eventBlock';
  id: string;
  alignment: BlockImageTextAlignment;
  backgroundColor: BackgroundColorType;
  header: string | null;
  eventTitle: string;
  date: string;
  time: string;
  location: string;
  details: string;
  linkLabel: string;
  url: string;
}

export interface BlockFacultyMembers {
  type: 'facultyMembersBlock';
  id: string;
  facultyMembers: FacultyMember[];
}

export interface BlockGallery {
  type: 'galleryBlock';
  id: string;
  header?: string;
  articles: ArticleLink[];
}

export interface BlockHero {
  type: 'heroBlock';
  id: string;
  heroImage?: Image;
  model: File3D;
  article?: ArticleLink;
}

export enum BlockImageTextAlignment {
  IMAGE_ON_LEFT = 'image-on-left',
  IMAGE_ON_RIGHT = 'image-on-right',
}

export interface BlockImageText {
  type: 'imageTextBlock';
  id: string;
  alignment: BlockImageTextAlignment;
  backgroundColor: BackgroundColorType;
  header: string | null;
  image: Image | null;
  article: ArticleLink | null;
}

export interface BlockQuote {
  type: 'quoteBlock';
  id: string;
  text: string;
}

export interface BlockSocialMedia {
  type: 'socialMediaBlock';
  id: string;
  headerLinkLabel: string;
  headerLinkUrl: string;
  images: SocialMediaImageAndUrl[];
}

export interface BlockText {
  type: 'textBlock';
  id: string;
  header: string;
  subheading: string;
  relatedLinks: RelatedLinks;
  content: BlockTextContent;
}

export type BlockTextContent = PortableText;

export interface BlockTopicsList {
  type: 'topicsListBlock';
  id: string;
  title: string;
  featuredTopics: TopicLink[];
}

export interface BlockVideo {
  type: 'videoBlock';
  id: string;
  videoId: string;
  article?: ArticleLink;
}

export interface BodyLinks {
  type: 'bodyLinks';
  id: string;
  title: string;
  style: string;
  links: Link[];
}

export interface Campuses {
  locations: Campus[];
}

export interface Campus {
  name: string;
  buildingName: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  directionsLink: Link;
  visitLink: Link;
}

export interface ColumnList {
  id: string;
  title: string;
  items: ListItem[];
}

export interface Department {
  id: string;
  name: string;
}

export interface Event {
  title?: string;
  date?: string;
  time?: string;
  location?: string;
  details?: string;
  linkLabel?: string;
  url?: string;
}

export interface FacultyMember {
  id: string;
  firstName: string;
  lastName: string;
  jobTitle: string;
  emailAddress: string;
  websiteLinkLabel: string;
  websiteUrl: string;
  department: Department;
  areasOfInterest: string;
  courses: string;
}

export interface File3D {
  id: string;
  src: string;
}

export interface GenericPage {
  id: string;
  title: string;
  slug: string;
  blocks: Block[];
  seo: SeoSettings;
  globalSettings: GlobalSettings;
  topics: TopicLink[];
}

export interface GenericPagePagination {
  page: number;
  total: number;
  genericPage: GenericPage | null;
  globalSettings: GlobalSettings | unknown;
  topics: TopicLink[] | unknown;
}

export interface GlobalSettings {
  notificationBar: NotificationBar | null;
  navigationMenu: NavigationMenu | null;
  pageNotFoundModel: File3D | null;
  globalDefaultImages: Image[];
}

export interface HeaderLinkWithListItem {
  text: string;
}

export interface HeaderLinkWithList {
  type: 'headerLinkWithList';
  id: string;
  headerText: string;
  headerUrl: string;
  listItems: HeaderLinkWithListItem[];
}

export interface Image {
  id: string;
  src: string;
  alt: string;
  caption: string;
  crop: ImageCrop | null;
  hotspot: object | null;
  metadata: {
    dimensions: ImageDimensions;
  } | null;
}

export interface ImageDimensions {
  width: number;
  height: number;
}

export interface ImageCrop {
  bottom?: number;
  left?: number;
  top?: number;
  right?: number;
}

export interface Link {
  url: string;
  linkLabel: string;
}

export enum LinkListStyleType {
  BRONZE_TITLE = 'bronze-title',
  BLACK_TITLE = 'black-title',
}

export enum LinkListVariantType {
  NAV = 'nav',
  ARTICLE_SIDEBAR = 'article',
  BODY = 'body',
}

export interface ListItem {
  id: string;
  title: string;
  text: string;
  link: Link;
}

export interface MainMenuLink {
  title: string;
  links: Link[];
}

export interface Mouse {
  x: number;
  y: number;
}

export interface NavMock {
  mainMenuLinks: RelatedLinks[];
  moreLinks: RelatedLinks;
  footerLinks: NavFooterLinks;
}

export interface NavFooterLinks {
  schools: SchoolLink[];
  socialMedia: Link[];
  additionalLinks: Link[];
}

export interface NavigationMenu {
  departments: NavigationMenuLinks;
  additionalLinks: NavigationMenuLinks;
  socialMediaLinks: NavigationMenuLinks;
  campuses: Campuses;
  generalFooterMenuLinks: NavigationMenuLinks;
}

export interface NavigationMenuLinks {
  title: string;
  links: Link[];
}

export enum Arrow {
  NORTHEAST = `\u2197`,
}

export interface NotificationBar {
  text: string;
  link: {
    linkLabel: string;
    url: string;
  };
  image: Image;
}

export type PortableText = object[];

export interface RelatedLinks {
  type: 'articleRelatedLinks';
  id?: string;
  title: string;
  links: Link[];
}

export interface RouteObject {
  path: string;
  exact: boolean;
  component?: any;
  base?: string;
}

export interface SchoolLink {
  header: string;
  name: string;
  address: string;
  city: string;
  state: string;
  zipCode: string;
  directionsUrl: string;
  visitUrl: string;
}

export interface SearchPage {
  id: string;
  title: string;
  slug: string;
  seo: SeoSettings;
  articles: ArticleLink[];
}

export interface SearchPagination {
  page: number;
  total: number;
  topics: TopicLink[];
  articles: ArticleLink[];
  facultyMembers: FacultyMember[];
  globalSettings: GlobalSettings;
}

export type SeoSettings = {
  title: string;
  description: string;
  image: Image;
};

export interface SocialMediaImageAndUrl {
  id: string;
  image: Image;
  url: string;
}

export interface TopicLink {
  id: string;
  title: string;
  slug: string;
  heroImage?: Image;
}

export interface TopicsPage {
  id: string;
  title: string;
  slug: string;
  hero: {
    id: string;
    type: 'heroBlock';
    heroImage: Image;
    model: File3D;
  };
  description: PortableText;
  seo: SeoSettings;
  articles: ArticleLink[];
}

export interface TopicsPagePagination {
  page: number;
  total: number;
  topicsPage: TopicsPage | null;
  globalSettings: GlobalSettings | unknown;
}

export interface TwoColumnTextBlock {
  id: string;
  type: 'twoColumnTextBlock';
  title: string;
  columns: ColumnList[];
  links: Link[];
}

export class View<Props extends ViewProps, State = {}> extends Component<Props, State> {}

export interface ViewProps {
  model: any;
}
