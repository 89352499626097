import React, { Component } from 'react';
import cx from 'classnames';
import Language from 'constants/Language';
import sanityImgUtil from 'utils/sanityImgUtil';
import { Img, Button } from 'components/base';

import { NotificationBar as INotificationBar } from 'types';

type Props = {
  data: INotificationBar;
  className?: string;
  notificationMarqueeIsActive: boolean;
  notificationBannerIsActive: boolean;
  toggleNotificationBar: () => void;
};

class NotificationBar extends Component<Props> {
  render() {
    const {
      data,
      className,
      notificationMarqueeIsActive,
      notificationBannerIsActive,
      toggleNotificationBar,
    } = this.props;

    return (
      <div
        className={cx(
          'NotificationBar none z-overlay md:block md:fixed t0 r0 l0 flex flex-col bg-color-black transition-shorter',
          className,
          {
            'NotificationBar--active':
              notificationMarqueeIsActive || notificationBannerIsActive,
          }
        )}
      >
        <div
          className={cx(
            'NotificationBar__container flex flex-row relative justify-between',
            {
              'NotificationBar__container--active': notificationMarqueeIsActive,
            }
          )}
        >
          <div className="NotificationBar__marquee z-overlay flex col-10">
            <div className="NotificationBar__marquee__text apercu z-overlay text-navigation color-white bg-black flex flex-row">
              <div className="flex flex-row pr5">
                <span className="pr2_5 flex items-center">{data.text}</span>
                <Button
                  className="flex items-center bg-color-transparent color-bronze"
                  containerClassName="flex items-center"
                  ariaLabel={Language.t('NotificationBar.hideButtonAriaLabel')}
                  to={data.link.url}
                  openInNewTab={true}
                >
                  {data.link.linkLabel}
                </Button>
              </div>
              <div className="flex flex-row">
                <span className="pr2_5 flex items-center">{data.text}</span>
                <Button
                  className="flex items-center bg-color-transparent color-bronze"
                  containerClassName="flex items-center"
                  ariaLabel={Language.t('NotificationBar.hideButtonAriaLabel')}
                  to={data.link.url}
                >
                  {data.link.linkLabel}
                </Button>
              </div>
            </div>
          </div>
          <div className="NotificationBar__gradient z-overlay flex flex-col col-2 items-end justify-center pr_4 md:pr_75">
            <Button
              className="NotificationBar__hide-button apercu text-navigation items-center bg-color-transparent color-white"
              ariaLabel={Language.t('NotificationBar.hideButtonAriaLabel')}
              onClick={toggleNotificationBar}
            >
              {Language.t('NotificationBar.hide')}
            </Button>
          </div>
        </div>
        {data.image.src && (
          <div
            className={cx('NotificationBar__banner z-notificationBanner grid-white bg-color-black flex justify-center items-center', {
              'NotificationBar__banner--active': notificationBannerIsActive,
            })}
          >
            <div className="NotificationBar__image-container h100 flex col-2 mxauto pb1_5">
              <Img
                className="NotificationBar__image h100"
                src={sanityImgUtil(data.image, 500)}
                alt="placeholder image"
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default NotificationBar;
