import { RouteComponentProps } from 'react-router-dom';

import ApiClient from 'lib/ApiClient';
import ContainerBase from 'lib/ContainerBase';
import TopicsPageView from 'views/TopicsPageView';
import { TopicsPagePagination } from 'types';

interface StateProps {
  model: TopicsPagePagination | null;
}

type Props = RouteComponentProps & StateProps;

class TopicsPageContainer extends ContainerBase<Props, typeof TopicsPageView> {
  model = (): any => {
    const { pathname } = window.location;

    return ApiClient.fetchRecentArticlesFromTopicBySlug(`/${pathname.slice(1).split('/')[1]}`, 1);
  };

  view = import('views/TopicsPageView');
}

export default TopicsPageContainer;
