import memoize from 'lodash/memoize';

import get from 'lodash/get';

import { ArticleStudentWorkBlock } from 'types';
import sanitizeImages from './sanitizeImages';

export default memoize((data: unknown): ArticleStudentWorkBlock => {
  return {
    type: get(data, '_type', 'articleStudentWorkBlock'),
    images: sanitizeImages(get(data, 'images', []))
  };
});
