import ImageGroq from './ImageGroq';
import ArticleLinkGroq from './ArticleLinkGroq';
import SocialMediaImageGroq from './SocialMediaImageGroq';
import FacultyMemberGroq from './FacultyMemberGroq';

export default `{ 
  "id": _key,
  "type": _type,
  "heroImage": heroImage${ImageGroq},
  "article": article->${ArticleLinkGroq},
  "articles": articles[]->${ArticleLinkGroq},
  "model": model{"id": _key, "src": asset->url},
  "images": images[]${SocialMediaImageGroq},
  "facultyMembers": facultyMembers[]->${FacultyMemberGroq} | order(lastName),
  "featuredTopics": featuredTopics[]->{ "id": _id, title, slug, heroImage${ImageGroq} },
  image${ImageGroq},
  ... 
}`;
