import ImageGroq from './ImageGroq';
import ArticleContentGroq from './ArticleContentGroq';
import ArticleAuthorsReferencesGroq from './ArticleAuthorsReferencesGroq'
import GalleryBlockGroq from './GalleryBlockGroq';

export default `{
  "id": _id,
  title,
  subheading,
  slug,
  "seo": {
    "image": seoImage${ImageGroq},
    "title": seoTitle,
    "description": seoDescription
  },
  "meta": {
    "createdAt": _createdAt,
    "updatedAt": _updatedAt,
    authorsInfo,
    topics[]->{ title, slug },
  },
  "hero": { 
    "heroImage": heroImage${ImageGroq},
    "model": model{"id": _key, "src": asset->url},
  },
  "content": { ${ArticleContentGroq('body')} },
  "studentWorkBlock": studentWorkBlock{
    "images": images[]${ImageGroq},
  },
  "authorsReferences": authorsReferences[]${ArticleAuthorsReferencesGroq},
  "galleryBlock": galleryBlock${GalleryBlockGroq},
  ...
}`;
