import React, { Component } from 'react';
import Player from '@vimeo/player';
import cx from 'classnames';
import get from 'lodash/get';

interface Props {
  videoId: string;
  className?: string;
  variant: 'article' | 'generic';
}

interface State {
  player: Player | null;
  error: boolean;
}

class Video extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      player: null,
      error: false,
    };
  }

  componentDidMount() {
    const videoId = get(this, 'props.videoId');
    if (videoId) {
      this.initializePlayer();
    } else {
      this.setState({ error: true });
    }
  }

  initializePlayer = () => {
    const videoId = get(this, 'props.videoId');

    const player = new Player(videoId, {
      id: videoId,
      controls: true,
      loop: false,
    });

    this.setState({ player });
  };

  render() {
    const { className, videoId, variant } = this.props;
    const { error } = this.state;

    return (
      <div
        className={cx('Video', className, {
          'grid-black-12-columns border-right-black': variant === 'generic',
        })}
      >
        {!error && videoId && (
          <div
            className={cx(
              'Video__video-container relative flex items-center justify-center ',
              {
                'py2 md:p4': variant === 'generic',
              }
            )}
          >
            <div
              className={cx('flex', {
                'col-10': variant === 'generic',
                'col-12': variant === 'article',
              })}
            >
              <div
                className={cx(
                  'Video__video-wrapper w100 h100 flex justify-center transition'
                )}
              >
                <div id={videoId} className="image-drop-shadow" />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Video;
