import React from 'react';
import cx from 'classnames';
import { Button } from 'components/base';
import { HeaderLinkWithList as IHeaderLinkWithList, HeaderLinkWithListItem } from 'types';

interface Props {
  className?: string;
  data: IHeaderLinkWithList | null;
}

const HeaderLinkWithList: React.FC<Props> = ({ data, className }) => {
  if (!data) {
    return null;
  }

  const { headerUrl, headerText, listItems } = data;

  return (
    <div
      className={cx('HeaderLinkWithList apercu flex flex-col', className)}
    >
      <Button
        className="HeaderLinkWithList__header apercu bg-color-transparent color-bronze transition-shorter"
        ariaLabel={headerUrl}
        to={headerUrl}
        label={headerText}
      />
      {listItems &&
        listItems.map((listItem: HeaderLinkWithListItem) => (
          <div className="HeaderLinkWithList__list-item apercu color-green" key={listItem.text}>
            {listItem.text}
          </div>
        ))}
    </div>
  );
};

export default HeaderLinkWithList;
