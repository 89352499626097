import React from 'react';
import Language from 'constants/Language';

import { Button } from 'components/base';
import { NavigationMenuLinks, Campuses, Campus, Link as ILink } from 'types';
import NewsletterForm from 'components/NewsletterForm';

interface Props {
  campuses: Campuses;
  socialMediaLinks: NavigationMenuLinks;
  generalLinks: NavigationMenuLinks;
}

const NavFooterMenu: React.FC<Props> = ({ campuses, socialMediaLinks, generalLinks }) => {
  return (
    <div className="NavFooterMenu bg-color-white col-12 flex flex-col lg-md:flex-row lg-md:mt1">
      <div className="flex flex-col lg-md:flex-row col-12 text-meta-xs justify-between apercu">
        <div className="lg-md:col-7 flex flex-row flex-wrap lg-md:nowrap justify-between">
          <div className="flex flex-col lg-md:col-3 pr1_5">
            <div className="color-black">{Language.t('Global.pratt')}</div>
            <div className="color-green">{Language.t('Global.schoolOfArchitecture')}</div>
          </div>
          {campuses &&
            campuses.locations.map((campus: Campus) => (
              <div
                className="flex flex-col col-6 lg-md:col-3 pb2 lg-md:pb0 pr1_5 apercu text-meta-xs"
                key={campus.name}
              >
                <div className="color-black">{campus.name}</div>
                <div className="color-black">{campus.buildingName}</div>
                <div className="color-green">{campus.address}</div>
                <div className="color-green">
                  {campus.city}, {campus.state}, {campus.zip}
                </div>
                {campus.directionsLink && campus.directionsLink.url && (
                  <Button
                    className="NavFooterMenu__link apercu text-meta-xs bg-color-transparent color-bronze"
                    ariaLabel={Language.t('NavFooterMenu.directionsButtonAriaLabel', {
                      address: campus.address,
                    })}
                    to={campus.directionsLink.url}
                    openInNewTab={true}
                    label={campus.directionsLink.linkLabel}
                  />
                )}
                {campus.visitLink && campus.visitLink.url && (
                  <Button
                    className="NavFooterMenu__link apercu text-meta-xs bg-color-transparent color-bronze"
                    ariaLabel={Language.t('NavFooterMenu.visitButtonAriaLabel')}
                    to={campus.visitLink.url}
                    openInNewTab={true}
                    label={campus.visitLink.linkLabel}
                  />
                )}
              </div>
            ))}
        </div>
        <div className="NavFooterMenu__newsletter lg-md:none">
          <NewsletterForm />
        </div>
        <div className="flex flex-row lg-md:col-5">
          <div className="NavFooterMenu__newsletter none lg-md:flex lg-md:col-5 lg-md:pr1_5">
            <NewsletterForm />
          </div>
          <div className="col-12 lg-md:col-7 flex lg-md:flex-row-reverse flex-wrap">
            {socialMediaLinks && (
              <div className="NavFooterMenu__social-media-links col-6">
                <div className="apercu text-meta-xs color-black">
                  {socialMediaLinks.title}
                </div>
                {socialMediaLinks.links &&
                  socialMediaLinks.links.map((link: ILink) => (
                    <div key={link.linkLabel}>
                      <Button
                        className="NavFooterMenu__link apercu text-meta-xs bg-color-transparent color-bronze"
                        ariaLabel={Language.t('NavFooterMenu.socialMediaButtonAriaLabel')}
                        to={link.url}
                        openInNewTab={true}
                        label={link.linkLabel}
                      />
                    </div>
                  ))}
              </div>
            )}
            {generalLinks && (
              <div className="NavFooterMenu__additional-links col-6 pr1 lg-md:pr1_5">
                <div className="apercu text-meta-xs color-black">
                  {generalLinks.title}
                </div>
                {generalLinks.links &&
                  generalLinks.links.map((link: ILink) => (
                    <div key={link.linkLabel}>
                      <Button
                        className="NavFooterMenu__link apercu text-meta-xs bg-color-transparent color-bronze"
                        ariaLabel={link.linkLabel || ''}
                        to={link.url}
                        openInNewTab={true}
                        label={link.linkLabel}
                      />
                    </div>
                  ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavFooterMenu;
