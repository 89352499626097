import Sanity, { PreviewClient } from 'lib/SanityClient';
import get from 'lodash/get';
import {
  GENERIC_PAGE_PAGINATION_PER_PAGE,
  TOPICS_PAGE_PAGINATION_PER_PAGE,
  SEARCH_PAGINATION_PER_PAGE,
} from 'constants/Pagination';

import sanitizeGlobalSettings from 'sanitizers/sanitizeGlobalSettings';
import sanitizeGenericPage from 'sanitizers/sanitizeGenericPage';
import sanitizeArticle from 'sanitizers/sanitizeArticle';
import sanitizeArticleLinkResponse from 'sanitizers/sanitizeArticleLinkResponse';
import sanitizeGenericPageTotalPaginationResponse from 'sanitizers/sanitizeGenericPageTotalPaginationResponse';
import sanitizeTopicsPage from 'sanitizers/sanitizeTopicsPage';
import sanitizeTopicsResponse from 'sanitizers/sanitizeTopicsResponse';
import sanitizeFacultyMembers from 'sanitizers/sanitizeFacultyMembers';

import {
  GenericPagePagination,
  GlobalSettings,
  ArticleResponse,
  TopicsPage,
  TopicsPagePagination,
  SearchPagination,
  TopicLink,
} from 'types';
import ImageGroq from './Groq/ImageGroq';
import GlobalSettingsGroq from './Groq/GlobalSettingsGroq';
import BlockGroq from './Groq/BlockGroq';
import ArticleGroq from './Groq/ArticleGroq';
import ArticleLinkGroq from './Groq/ArticleLinkGroq';
import FacultyMemberGroq from './Groq/FacultyMemberGroq';

const ApiClient: {
  fetchAllTopics(): Promise<TopicLink[] | null>;
  fetchArticle(slug: string, preview?: string): Promise<ArticleResponse | null>;
  fetchRecentArticlesFromTopicBySlug(
    slug: string,
    page: number
  ): Promise<TopicsPagePagination>;
  fetchGenericPagePagination: (
    slug: string,
    page: number
  ) => Promise<GenericPagePagination>;
  fetchGlobalSettings(): Promise<GlobalSettings>;
  fetchTopicsPage(slug: string, preview?: string): Promise<TopicsPage | null>;
  fetchRecentResultsFromSearch: (
    searchString: string,
    page: number
  ) => Promise<SearchPagination>;
} = {
  async fetchAllTopics() {
    const response: any = await Sanity.fetch(
      `*[_type == 'topicsPage'] {
        "id": _id,
        title,
        slug
      } | order(title asc)`
    );
    return sanitizeTopicsResponse(response);
  },
  async fetchGenericPagePagination(slug, page) {
    const start =
      page * GENERIC_PAGE_PAGINATION_PER_PAGE - GENERIC_PAGE_PAGINATION_PER_PAGE;
    const end = page * GENERIC_PAGE_PAGINATION_PER_PAGE;

    const fetchGenericPage = async () => {
      const response = await Sanity.fetch(
        `*[_type == 'genericPage' && slug == '${slug}'][0] {
          _id, 
          title,
          "seo": {
            "image": seoImage${ImageGroq},
            "title": seoTitle,
            "description": seoDescription
          },
          slug,
          "blocks": blocks[] | [${start}...${end}] ${BlockGroq},
        }`
      );

      return response;
    };
    const fetchTotal = async () => {
      const response: any = await Sanity.fetch(
        `*[_type == 'genericPage' && slug == '${slug}'][0] {
          "totalNumberOfBlocks": count(blocks)}`
      );

      return sanitizeGenericPageTotalPaginationResponse(response);
    };

    return Promise.all([
      fetchGenericPage(),
      fetchTotal(),
      this.fetchGlobalSettings(),
      this.fetchAllTopics(),
    ]).then(
      ([
        fetchGenericPageResponse,
        fetchTotalResponse,
        fetchGlobalSettingsResponse,
        fetchAllTopicsResponse,
      ]) => {
        const total = fetchTotalResponse as number;
        const genericPage = sanitizeGenericPage(fetchGenericPageResponse);
        const globalSettings = fetchGlobalSettingsResponse;
        const topics = fetchAllTopicsResponse;

        return { page, total, genericPage, globalSettings, topics };
      }
    );
  },
  async fetchGlobalSettings() {
    const response = await Sanity.fetch(
      `*[_type == 'globalSettings'][0]${GlobalSettingsGroq}`
    );

    return sanitizeGlobalSettings(response);
  },
  async fetchArticle(slug, preview = '') {
    const fetchArticle = async () => {
      const response: any = !!preview
        ? await PreviewClient.getDocument(preview)
        : await Sanity.fetch(
            `*[_type == 'article' && slug == '${slug}'][0]${ArticleGroq}`
          );

      return response;
    };

    return Promise.all([fetchArticle(), this.fetchGlobalSettings()]).then(
      ([fetchArticleResponse, fetchGlobalSettingsResponse]) => {
        const article = sanitizeArticle(fetchArticleResponse);
        const globalSettings = fetchGlobalSettingsResponse;

        return { article, globalSettings };
      }
    );
  },
  async fetchTopicsPage(slug, preview = '') {
    const response = !!preview
      ? await PreviewClient.getDocument(preview)
      : await Sanity.fetch(
          `*[_type == 'topicsPage' && slug == '${slug}'][0] {
          _id, 
          title,
          "seo": {
            "image": seoImage${ImageGroq},
            "title": seoTitle,
            "description": seoDescription
          },
          slug,
          "hero": { 
            "heroImage": heroImage${ImageGroq},
            "model": model{"id": _key, "src": asset->url},
          },
          "description": description,          
        }`
        );

    return sanitizeTopicsPage(response);
  },
  async fetchRecentArticlesFromTopicBySlug(slug: string, page: number) {
    const start = page === 1 ? 0 : (page - 1) * TOPICS_PAGE_PAGINATION_PER_PAGE + 2;

    const end = page * TOPICS_PAGE_PAGINATION_PER_PAGE + 2;

    const fetchTopicsPage = () => {
      return Sanity.fetch(
        `*[_type == 'topicsPage' && slug == '${slug}'][0] {
          _id, 
          title,
          "seo": {
            "image": seoImage${ImageGroq},
            "title": seoTitle,
            "description": seoDescription
          },
          slug,
          "hero": { 
            "heroImage": heroImage${ImageGroq},
            "model": model{"id": _key, "src": asset->url},
          },
          "description": description,     
          "articles": *[ 
            _type == "article" &&
            ^._id in topics[]._ref
          ] | order(_createdAt desc) [${start}...${end}] ${ArticleLinkGroq}    
        }`
      );
    };

    const fetchTotal = () => {
      return Sanity.fetch(
        `*[_type == 'topicsPage' && slug == '${slug}'][0] {
          "total": count(*[_type == 'article' && ^._id in topics[]._ref])
        }`
      );
    };

    return Promise.all([
      fetchTopicsPage(),
      fetchTotal(),
      this.fetchGlobalSettings(),
    ]).then(
      ([fetchTopicsPageResponse, fetchTotalResponse, fetchGlobalSettingsResponse]) => {
        const topicsPage = sanitizeTopicsPage(fetchTopicsPageResponse);
        const total = get(fetchTotalResponse, 'total', 0) as number;
        const globalSettings = fetchGlobalSettingsResponse;

        return { page, total, topicsPage, globalSettings };
      }
    );
  },

  async fetchRecentResultsFromSearch(searchString: string, page: number) {
    const start = page === 1 ? 0 : (page - 1) * SEARCH_PAGINATION_PER_PAGE;

    const end = page * SEARCH_PAGINATION_PER_PAGE;

    const fetchArticles = async () => {
      return Sanity.fetch(`
      *[
        (_type == 'article') &&
        [title] match ["${searchString}"]
        || [topics[]->title] match ["${searchString}"]
      ] | order(_createdAt desc) [${start}...${end}] ${ArticleLinkGroq}`);
    };

    const fetchFacultyMembers = async () => {
      return Sanity.fetch(`
      *[
        (_type == 'facultyMember') &&
        [areasOfInterest] match ["${searchString}"] 
        || [courses] match ["${searchString}"] 
        || [department->name] match ["${searchString}"]
      ] | order(_createdAt desc) ${FacultyMemberGroq}`);
    };

    const fetchTotalNumberOfArticles = async () => {
      return Sanity.fetch(
        `count(
          *[ 
            _type == 'article' &&
            [title] match ["${searchString}"]
            || [topics[]->title] match ["${searchString}"]
          ]
        )`
      );
    };

    return Promise.all([
      this.fetchAllTopics(),
      fetchArticles(),
      fetchFacultyMembers(),
      this.fetchGlobalSettings(),
      fetchTotalNumberOfArticles(),
    ]).then(
      ([
        fetchAllTopicsResponse,
        fetchArticlesResponse,
        fetchFacultyMembersResponse,
        fetchGlobalSettingsResponse,
        fetchTotalNumberOfArticlesResponse,
      ]) => {
        const topics = sanitizeTopicsResponse(fetchAllTopicsResponse as unknown[]);
        const articles = sanitizeArticleLinkResponse(fetchArticlesResponse as unknown[]);
        const facultyMembers = sanitizeFacultyMembers(
          fetchFacultyMembersResponse as unknown[]
        );
        const globalSettings = sanitizeGlobalSettings(
          fetchGlobalSettingsResponse as unknown
        );
        const total = fetchTotalNumberOfArticlesResponse as number;

        return { page, total, topics, articles, facultyMembers, globalSettings };
      }
    );
  },
};

export default ApiClient;
