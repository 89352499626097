import { RouteComponentProps } from 'react-router-dom';

import ApiClient from 'lib/ApiClient';
import ContainerBase from 'lib/ContainerBase';
import GenericPageView from 'views/GenericPageView';
import { GenericPage } from 'types';
import { RouteMap } from 'constants/RouteMap';

interface StateProps {
  model: GenericPage | null;
}

type Props = RouteComponentProps & StateProps;

class GenericPageContainer extends ContainerBase<Props, typeof GenericPageView> {
  model = (): any => {
    const { pathname } = window.location;
    const slug = pathname === '/' ? RouteMap.HOME.path : pathname;

    return ApiClient.fetchGenericPagePagination(slug, 1);
  };

  view = import('views/GenericPageView');
}

export default GenericPageContainer;
