//TO-DO: Update placeholder images
import hashify from 'object-hash';

export const DefaultImage = {
  id: hashify('assets/images/DefaultImage.png'),
  src: 'assets/images/DefaultImage.png',
  alt: 'Pratt School of Architecture Default Image',
  caption: '',
  crop: null,
  hotspot: null,
  metadata: null,
};
