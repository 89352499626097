import memoize from 'lodash/memoize';
import get from 'lodash/get';
import { DefaultImage } from '../constants/Default';

import { GlobalSettings } from 'types';
import sanitizeNotificationBar from 'sanitizers/sanitizeNotificationBar';
import sanitizeNavigationMenu from 'sanitizers/sanitizeNavigationMenu';
import sanitizeImages from 'sanitizers/sanitizeImages';

export default memoize(
  (globalSettings: unknown): GlobalSettings => {
    return {
      notificationBar: sanitizeNotificationBar(get(globalSettings, 'notificationBar', '')),
      navigationMenu: sanitizeNavigationMenu(get(globalSettings, 'navigationMenu', '')),
      pageNotFoundModel: get(globalSettings, 'pageNotFoundModel', ''),
      globalDefaultImages: sanitizeImages(get(globalSettings, 'globalDefaultImages', [DefaultImage]))
    };
  }
);
