import React, { Component } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import 'styles/App.scss';
import get from 'lodash/get';
import throttle from 'lodash/throttle';
import cx from 'classnames';

import { GlobalSettings } from 'types';
import ApiClient from 'lib/ApiClient';
import Nav from 'components/Nav';
import Routes from 'constants/routes';
import Loader from 'components/Loader';
import ScrollToTop from 'components/ScrollToTop';

interface State {
  initializeApplicationFulfilled: boolean;
  secondaryNavIsActive: boolean;
  notificationMarqueeIsActive: boolean;
  notificationBannerIsActive: boolean;
  notificationBarIsHidden: boolean;
  mainMenuIsActive: boolean;
  globalSettings: GlobalSettings | null;
}

type Props = {};

class App extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      initializeApplicationFulfilled: false,
      secondaryNavIsActive: false,
      notificationMarqueeIsActive: false,
      notificationBannerIsActive: false,
      notificationBarIsHidden: false,
      mainMenuIsActive: false,
      globalSettings: null,
    };
  }

  componentDidMount() {
    if (!this.state.initializeApplicationFulfilled) {
      this.intializeApplication();
    }

    this.handleScroll();

    const pageOffset =
      get(window, 'pageYOffset', 0) || get(document, 'documentElement.scrollTop', 0);

    if (pageOffset === 0) {
      this.setState({
        notificationBannerIsActive: true,
        notificationMarqueeIsActive: true,
      });
    }

    window.addEventListener('scroll', this.throttleHandleScrollTop);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.throttleHandleScrollTop);
  }

  intializeApplication = () => {
    return ApiClient.fetchGlobalSettings().then((response: GlobalSettings) =>
      this.setState((state: State) => ({
        initializeApplicationFulfilled: true,
        globalSettings: response,
      }))
    );
  };

  hideNotificationBar = () => {
    this.setState({
      notificationMarqueeIsActive: false,
      notificationBannerIsActive: false,
      notificationBarIsHidden: true,
    });
  };

  handleMainMenuClick = () => {
    if (this.state.mainMenuIsActive) {
      this.setState({
        mainMenuIsActive: !this.state.mainMenuIsActive,
      });
    }

    return null;
  };

  toggleMainMenu = () => {
    this.setState({
      mainMenuIsActive: !this.state.mainMenuIsActive,
    });
  };

  handleScroll = () => {
    const pageOffset =
      get(window, 'pageYOffset', 0) || get(document, 'documentElement.scrollTop', 0);

    if (pageOffset === 0 && !this.state.secondaryNavIsActive) {
      this.setState({
        notificationMarqueeIsActive: true,
        notificationBannerIsActive: true,
        secondaryNavIsActive: true,
      });
    }

    if (pageOffset > 0 && this.state.secondaryNavIsActive) {
      this.setState({
        secondaryNavIsActive: false,
      });
    }

    if (pageOffset > 0 && !this.state.notificationMarqueeIsActive) {
      this.setState({
        notificationMarqueeIsActive: true,
      });
    }

    if (pageOffset > 0 && this.state.notificationBannerIsActive) {
      this.setState({
        notificationBannerIsActive: false,
      });
    }
  };

  throttleHandleScrollTop = throttle(this.handleScroll, 150);

  calculatePaddingTop = () => {
    if (this.state.mainMenuIsActive) {
      return '50vh';
    }
  };

  render() {
    const {
      secondaryNavIsActive,
      notificationBannerIsActive,
      notificationMarqueeIsActive,
      notificationBarIsHidden,
      mainMenuIsActive,
      globalSettings,
      initializeApplicationFulfilled,
    } = this.state;

    const hasSecondaryNavActiveStyling = secondaryNavIsActive && !notificationBarIsHidden;

    const notificationBarSecondaryNavInactive =
      notificationBarIsHidden && !secondaryNavIsActive && !mainMenuIsActive;

    if (!initializeApplicationFulfilled) {
      return (
        <div className="Loader bg-color-white vw100 vh100">
          <Loader className="Loader__svg" />
        </div>
      );
    }

    return (
      <main>
        <div className="App">
          <Router>
            <ScrollToTop />
            <Nav
              toggleNotificationBar={this.hideNotificationBar}
              handleMainMenuClick={this.handleMainMenuClick}
              toggleMainMenu={this.toggleMainMenu}
              notificationBannerIsActive={notificationBannerIsActive}
              notificationMarqueeIsActive={notificationMarqueeIsActive}
              notificationBarIsHidden={notificationBarIsHidden}
              mainMenuIsActive={mainMenuIsActive}
              secondaryNavIsActive={secondaryNavIsActive}
              globalSettings={globalSettings}
            />
            <div
              style={{
                paddingTop: this.calculatePaddingTop(),
                minHeight: 'calc(100vh + 1px)',
              }}
              className={cx(
                'view site-padding-x site-padding-y transition-shorter flex flex-col',
                {
                  'view--notification-bar-secondary-nav-active': hasSecondaryNavActiveStyling,
                  'view--notification-bar-secondary-nav-inactive': notificationBarSecondaryNavInactive,
                  'view--notification-bar-inactive':
                    notificationBarIsHidden && !notificationBarSecondaryNavInactive,
                  'view--notification-bar-inactive-main-menu-active':
                    mainMenuIsActive && notificationBarIsHidden,
                  'view--primary-nav-active-notification-marquee-active':
                    !secondaryNavIsActive && notificationMarqueeIsActive,
                }
              )}
            >
              <Routes />
            </div>
          </Router>
        </div>
      </main>
    );
  }
}

export default App;
