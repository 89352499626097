import ArticleContainer from 'containers/ArticleContainer';
import GenericPageContainer from 'containers/GenericPageContainer';
import TopicsPageContainer from 'containers/TopicsPageContainer';
import SearchContainer from 'containers/SearchContainer';

import { RouteObject } from 'types';

export const RouteMap: { [id: string]: RouteObject } = {
  HOME: {
    path: '/research',
    exact: true,
    component: GenericPageContainer
  },
  ARTICLE: {
    path: '/articles/:articleSlug',
    exact: true,
    component: ArticleContainer
  },
  TOPICS: {
    path: '/topics/:topicsSlug',
    exact: true,
    component: TopicsPageContainer
  },
  SEARCH: {
    path: '/search',
    exact: true,
    component: SearchContainer,
    base: '/search'
  },
};
