import get from 'lodash/get';
import memoize from 'lodash/memoize';

import { ArticleLink } from 'types';
import sanitizeTopics from 'sanitizers/sanitizeTopics';
import sanitizeImage from 'sanitizers/sanitizeImage';

export default memoize(
  (article: unknown): ArticleLink => {
    return {
      id: get(article, 'id', ''),
      title: get(article, 'title', ''),
      briefTitle: get(article, 'briefTitle', ''),
      slug: get(article, 'slug', ''),
      authorsInfo: get(article, 'authorsInfo', {}),
      heroImage: sanitizeImage(get(article, 'heroImage', {})),
      model: {
        id: get(article, 'model.id', ''),
        src: get(article, 'model.src', ''),
      },
      date: get(article, 'releaseDate') || get(article, 'createdAt'),
      topics: sanitizeTopics(get(article, 'topics', []))
    };
  }
);
